@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500&display=swap');

$font-family: 'inter', sans-serif;

// colors
$primary: #00e1be;
$white: #ffffff;
$black: #000000;
$text-dark: #1d1d1b;
$button-text-color: #222;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  font-family: $font-family;
}

p {
  font-size: 1.2rem;
}

.container-fluid {
  max-width: 1440px;
}

.text-danger {
  color: #b00 !important;
}

//helper classes
// color helpers
.brand-color {
  color: $primary;
}

// button styles
// default-btn
.btn,
button {
  cursor: pointer !important;
}

.btn-default {
  background-color: $primary;
  color: $black;
  height: 66px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid $primary;
  border-radius: 8px;
  transition: ease 0.1s;
  cursor: pointer;
  padding: 0 30px;
  text-decoration: none;
  white-space: nowrap;

  &:hover,
  &:focus {
    box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    background-color: #03d0b0;
    border-color: #03d0b0;
    color: $black;
  }

  &:active {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    background-color: $black;
    border-color: $black;
    color: $white;
  }

  &:disabled,
  &[disabled] {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    background-color: $black;
    border-color: $black;
    color: $white;
    opacity: 0.8;
    cursor: default;
  }
}

.btn-outline {
  background-color: transparent;
  color: $black;
  height: 66px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid $black;
  border-radius: 8px;
  padding: 0 40px;
  transition: ease 0.1s;
  cursor: pointer;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $black;
    color: $white;
    box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
  }

  &:active {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
  }

  &:disabled,
  &[disabled] {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    background-color: $black;
    border-color: $black;
    color: $white;
    opacity: 0.8;
    cursor: default;
  }

  &.icon-left-btn {
    height: 34px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 15px;
    border-radius: 4px;
    border-width: 1px;
    transition: ease 0.1s;
    cursor: pointer;

    img {
      height: 14px;
      margin-right: 8px;
    }

    &:hover,
    &:focus {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.icon-right-btn {
    height: 34px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 15px;
    border-radius: 4px;
    border-width: 1px;
    transition: ease 0.1s;
    cursor: pointer;

    img {
      height: 14px;
      margin-left: 8px;
    }

    &:hover,
    &:focus {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.btn-share {
  border: none;
  background-color: rgba(29, 29, 27, 0.3);
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  height: 31px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;

  img {
    height: 10px;
    margin-right: 8px;
  }

  &:hover {
    transform: translateY(-2px);
    background-color: $black;
  }

  &:active {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
  }
}

// switch styles
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

// small container style
.container {
  &.small-container {
    max-width: 800px;
  }
}

// Home styles------------------------------------
.authentication {
  position: relative;
  color: white;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../assets/images/login-bg.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;

  .authentication-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;

    .welcome {
      padding-top: 30px;
      padding-bottom: 15px;

      .welcome-content {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        .logo {
          width: 100px;
          margin-bottom: 40px;
        }

        //claim todo
        .vendor-logo {
          width: auto;
          height: 90px;
          margin-bottom: 37px;
          border: 2px solid $primary;
          padding: 2px;
        }

        //claim todo
        .locked-away-text {
          font-size: 20px;
          margin-bottom: 0px;

          &.text-left {
            text-align: left;
            margin-bottom: 20px;
          }
        }

        .amount-text {
          font-size: 22px;
          margin-bottom: 6px;

          b {
            font-weight: bold;
          }
        }

        .vendor-text {
          font-size: 16px;
          margin-bottom: 47px;
        }

        .saying-text {
          font-size: 15px;
          color: $primary;
          max-width: 240px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
        }

        h2 {
          font-size: 36px;
          line-height: 1.5;
        }

        .data {
          table {
            width: 100%;
            max-width: 300px;
            font-size: 18px;

            tbody {
              tr {
                border-bottom: 1px solid #00e1be !important;

                td {
                  text-align: left;
                  min-width: 100px;

                  &:first-child {
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }

    .signin {
      color: black;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      background-color: $white;
      margin: 0 -0.75rem;
      width: calc(100% + 1.5rem);

      .signin-content {
        padding: 47px 20px 30px 20px;

        .keep-track-text {
          margin-bottom: 36px;
          font-size: 20px;
          color: $text-dark;
          font-weight: 600;

          @media (max-width: 767.98px) {
            font-size: 16px;
          }
        }

        .learn-more-text {
          margin-top: 42px;
          font-size: 16px;

          a {
            font-weight: bold;
            color: $black;
          }
        }

        .button-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 30px 0;

          button,
          a {
            margin-bottom: 10px;
            background-color: $primary;
            color: $button-text-color;
            border: none;
            box-shadow: none;
            width: 100% !important;

            @media (max-width: 768px) {
              width: 100% !important;
            }
          }

          .sign-button-with-label {
            width: 50%;
            display: flex;
            flex-direction: column;

            span {
              font-size: 14px;
              margin-bottom: 5px;
              font-weight: 500;
              color: #707070;
            }

            &:first-child {
              margin-right: 5px;

              @media (max-width: 768px) {
                margin-right: 0px;
              }
            }

            &:last-child {
              margin-left: 5px;

              @media (max-width: 768px) {
                margin-top: 15px;
                margin-left: 0px;
              }
            }

            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }

        .guest-link {
          color: $primary;
        }

        .recaptcha-wrapper {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .authentication-wrapper {
      justify-content: center;

      .signin {
        border-radius: 16px;
        max-width: 500px;
        margin-bottom: 30px;

        .signin-content {
          .button-wrapper {
            flex-direction: row;
            width: 100%;
          }
        }
      }
    }
  }

  &.claim {
    .authentication-wrapper {
      .welcome {
        padding-top: 30px;

        .welcome-content {
          .logo {
            height: 44px;
            width: auto;
            margin-bottom: 60px;
          }

          .vendor-logo {
            height: 100px;
            width: auto;
            margin-bottom: 20px;
            background-color: lightgray;
            border: 1px solid lightgray;
            border-radius: 10px;
          }

          .locked-away-text {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
          }

          .amount-text {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 6px;

            b {
              font-size: 22px;
              font-weight: 900;
            }
          }

          .vendor-text {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 60px;
          }

          .saying-text {
            font-size: 18px;
            color: $primary;
            max-width: 310px;
            font-weight: bold;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .claim-carbon {
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 22px;
            margin-top: 40px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// homepage dashboard styles
.home-page-dashboard {
  background-color: $primary;

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (min-width: 1024px) {
    background-color: $white;
    padding-top: 84px;
    padding-bottom: 30px;
  }
}

.home-nav {
  padding: 20px;

  .home-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 34px;
    }

    .round-button {
      background-color: #000000;
      color: #fff;
      border: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0px;
    }

    .web-buttons {
      display: flex;

      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }

    .mobile-buttons {
      display: none;

      @media only screen and (max-width: 1024px) {
        display: flex;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    background-color: $primary;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    // .logo {
    //   filter: invert(1);
    // }

    .btn-outline {
      &.icon-left-btn {
        // border-color: $white;
        // color: $white;

        // img {
        //   filter: invert(1);
        // }

        // &:hover {
        //   border-color: $primary;
        //   background-color: $primary;
        //   color: $black;

        //   img {
        //     filter: invert(0);
        //   }
        // }
      }
    }
  }
}

.home-nav.payment-page-nav {
  position: unset;
  padding: 20px;

  @media only screen and (max-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
  }

  .home-nav-wrapper {
    justify-content: center;

    @media (max-width: 768px) {
      justify-content: flex-end;
    }

    @media (max-width: 992px) {
      justify-content: flex-end;
    }
  }

  .logo-wrapper {
    display: none;
    width: fit-content;

    .logo-top {
      width: 100px;

      &:hover {
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 992px) {
      display: block;
    }
  }
}

.home-nav-dark {
  background-color: #000;

  .logo-top {
    filter: invert(1);
  }

  .round-button {
    background-color: #fff !important;

    svg {
      color: #000;
    }
  }

  @media only screen and (min-width: 1024px) {
    .btn-outline {
      &.icon-left-btn {
        border-color: $white;
        color: $white;

        img {
          filter: invert(1);
        }

        &:hover {
          border-color: $primary;
          background-color: $primary;
          color: $black;

          img {
            filter: invert(0);
          }
        }
      }
    }
  }
}

.thankyou-page-nav {
  display: none;

  @media only screen and (max-width: 992px) {
    display: block;
  }
}

.dashboard-info {
  h2 {
    font-size: 22px;
    line-height: 41px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 40px;
    text-align: center;
  }

  .data {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 27px;
    flex-wrap: wrap;

    h3 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 23px;
      font-size: 40px;
      line-height: 41px;
      font-weight: bold;

      span {
        display: block;
        font-size: 22px;
        line-height: 41px;
        font-weight: normal;
      }

      @media only screen and (max-width: 992px) {
        font-size: 34px;

        span {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .data {
      h3 {
        color: $primary;

        span {
          color: $black;
        }
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  .btn-outline {
    margin-top: 40%;
  }
}

// recent transactions
.recent-transactions {
  background-color: #ffffff;
  padding-top: 32px;
  border-radius: 32px 32px 0 0;
  margin-top: 48px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 25px 20px 25px;

    label {
      font-size: 22px;
      line-height: 41px;
      font-weight: bold;
    }

    a {
      font-size: 16px;
      font-weight: 600;
      line-height: 41px;
      cursor: pointer;
      color: black;
      text-decoration: none;

      img {
        width: 12px;
        margin-left: 8px;
        transform: rotate(180deg);
      }
    }

    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 44px;
      cursor: pointer;
      color: black;
      text-decoration: none;

      img {
        width: 12px;
        margin-left: 8px;
        transform: rotate(180deg);
      }

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &--body {
    text-align: center;
    min-height: 400px;

    .btn-outline.icon-right-btn {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        transform: rotate(180deg);
      }
    }

    .react-loading-skeleton {
      max-width: calc(100% - 30px);
    }
  }

  @media only screen and (min-width: 1024px) {
    border: 2px solid #eeeeee;
    max-width: 600px;
    border-radius: 16px;

    &--header {
      padding: 0 20px 20px 16px;
    }

    &--body {
      padding-bottom: 10px;

      .btn-outline.icon-right-btn {
        display: none;
      }
    }
  }
}

// transaction item styles
.transaction-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 8px 16px;

  .item-image {
    background-color: #ffffff;
    height: 0;
    padding-top: 67px;
    width: 67px;
    min-width: 67px;
    position: relative;
    margin-right: 20px;

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .details {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .date {
      font-size: 13px;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 4px;
    }

    .name {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }

    .type {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
  }

  .weight {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .share-icon {
    filter: invert(50%);
    position: absolute;
    right: 18px;
    bottom: 14px;
    height: 17px;

    &:hover {
      filter: invert(71%) sepia(70%) saturate(3747%) hue-rotate(127deg) brightness(105%) contrast(101%);
    }
  }

  &.selected {
    background-color: rgba(188, 255, 42, 0.3);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
    cursor: pointer;
  }
}

.transaction-item-wrapper {
  padding-bottom: 20px;
  font-weight: 600;

  .transaction-date {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    line-height: 21px;
    margin-left: 16px;
    margin-bottom: 5px;
  }
}

//loader styling todo
.loader {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
}

.loader-logo {
  height: 10vh;
}

//loader styling todo

//claim todo
.claim {
  background: none;
  color: #000;
}

.claim button {
  width: 100%;
  margin-top: 10px;
}

//claim todo

//profile todo
.profile-card {
  background-color: $primary;
  border-radius: 8px;
  border-color: $primary;

  .card-body {
    padding: 20px 27px;
    position: relative;
    display: flex;
    flex-direction: column;

    .card-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-text {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 3px;

      &.joined {
        margin-bottom: 9px;
      }

      b {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.4);
        margin-right: 23px;
        min-width: 70px;
        display: inline-block;
      }
    }

    .icon-left-btn {
      position: absolute;
      bottom: 8px;
      right: 10px;
    }
  }
}

.newsletter-offers {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 61px;
  padding-bottom: 24px;

  .text {
    display: flex;
    flex-direction: column;

    label {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 2px;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.4);
      max-width: 180px;
    }
  }
}

.btn-full {
  padding: 13px 8px 13px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  margin-bottom: 20px;
  transition: ease 0.1s;
  background-color: transparent;
  border: none;
  width: 100%;

  &:hover {
    color: black;
    background-color: rgba(0, 0, 0, 0.01);
    padding-left: 8px;
  }

  span {
    font-size: 18px;
    line-height: 20px;
  }

  img {
    height: 14px;
  }
}

.c2zero-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  padding-top: 15px;

  a {
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: ease 0.3s;
    padding: 10px 24px 10px 0;

    img {
      height: 20px !important;
    }

    &:hover {
      transform: translateX(-4px);
    }
  }

  span {
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: ease 0.3s;

    img {
      height: 20px !important;
    }

    &:hover {
      transform: translateX(-4px);
    }
  }

  h1 {
    margin: 0;
    font-size: 34px;
    line-height: 41px;
    font-weight: 900;
  }
}

//share modal
.share-modal {
  .modal-close-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    img {
      height: 24px;
    }
  }

  .modal-body {
    .logo {
      height: 34px;
      margin: 0 auto;
      display: block;
      margin-top: 29px;
      margin-bottom: 34px;
    }

    .dashboard-info {
      .data {
        h3 {
          font-size: 30px;
          line-height: 31px;

          span {
            font-size: 18px;
            line-height: 31px;
          }
        }
      }
    }

    .share-btns {
      text-align: center;

      h3 {
        font-size: 22px;
        line-height: 41px;
        font-weight: bold;
      }

      .share-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
      }

      .facebook-btn {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ebebeb !important;
        margin: 0 23px;
        position: relative;

        &:hover {
          background-color: #d0cdcd !important;
        }

        img {
          height: 24px;
        }

        &::after {
          content: 'Facebook';
          position: absolute;
          bottom: -25px;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .twitter-btn {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ebebeb !important;
        margin: 0 23px;
        position: relative;

        &:hover {
          background-color: #d0cdcd !important;
        }

        img {
          height: 19.5px;
        }

        &::after {
          content: 'Twitter';
          position: absolute;
          bottom: -25px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  &.share-individual {
    .logo {
      height: 87px;
      margin-bottom: 5px;
      border: 1px solid #46546540;
    }

    .dashboard-info {
      h1 {
        text-align: center;
        font-size: 28px;
        margin-bottom: 0;
        line-height: 41px;
        font-weight: 900;

        span {
          font-size: 20px;
          line-height: 41px;
          font-weight: 600;
        }
      }

      h2 {
        margin-top: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 30px;
      }

      .data {
        flex-direction: column;

        .data-item {
          width: 100%;
          max-width: 300px;
          margin: 5px auto;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          span {
            font-size: 20px;
            line-height: 28px;
            font-weight: normal;
            text-align: right;
            padding-left: 5px;
          }

          .item-name {
            color: #708098;
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// page loader styles
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);

  img {
    height: 200px;
  }
}

.empty-dashboard-msg {
  text-align: center;
  padding: 20px;

  img {
    height: 150px;
  }

  h2 {
    font-weight: 900;
    margin: 20px 0;
  }

  p {
    max-width: 500px;
    font-size: 16px;
    margin: 0 auto 50px auto;
  }

  .btn-default {
    margin-bottom: 20px;
  }

  .order-btn {
    width: 75%;

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  .find-it-btn {
    background: none;
    border: 1px solid #000;
    width: 75%;

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }
}

.floating-msg {
  position: fixed;
  bottom: 50px;
  left: calc(50% - 140px);
  right: calc(50% - 140px);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  z-index: 999;
  align-items: center;
  justify-content: space-between;
  display: none;
  transition: ease 0.5s;

  &.show {
    display: inline-flex;
  }

  img {
    height: 20px;

    padding-left: 10px;
    cursor: pointer;
  }

  h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
    padding-right: 10px;
    border-right: 1px solid rgb(0, 0, 0, 0.19);
  }

  &.success {
    background-color: $primary;
    color: $black;
  }

  &.error {
    background-color: #ea6673;
  }
}

// locking loader
.locking-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.2rem;
  }

  padding-bottom: 50px;

  &.dark {
    p {
      font-weight: bold;
    }

    .lf-player-container {
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 4px;
      margin-bottom: 20px;
    }
  }
}

// css loader
.loader1 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
}

.loader1 span {
  vertical-align: middle;
  border-radius: 100%;

  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: loader1 0.8s linear infinite alternate;
  animation: loader1 0.8s linear infinite alternate;
}

.loader1 span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 255, 190, 0.6);
}

.loader1 span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 255, 190, 0.8);
}

.loader1 span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  background: rgba(0, 255, 190, 1);
}

.loader1 span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 255, 190, 0.8);
}

.loader1 span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 255, 190, 0.4);
}

@keyframes loader1 {
  from {
    transform: scale(0, 0);
  }

  to {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes loader1 {
  from {
    -webkit-transform: scale(0, 0);
  }

  to {
    -webkit-transform: scale(1, 1);
  }
}

//account delete notification banner
.account-delete-notification {
  min-height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  background-color: red;
  color: $white;
  font-size: 11px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  .countdown {
    font-weight: bold;
    padding: 0 5px;
  }

  a {
    margin: 5px 0px 5px 10px;
    color: red;
    padding: 4px 10px;
    border-radius: 3px;
    background: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.page-layout.delete-nav {
  padding-top: 44px;

  .home-nav {
    top: 44px;
  }
}

.page-email-verification {
  .signin {
    @media (max-width: 767.98px) {
      width: 100% !important;
      min-width: 375px;
      min-height: 650px !important;
    }
  }

  .frm-var {
    .frm-field {
      text-align: left;
      padding-bottom: 24px;

      .lbl-var {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.01em;
        text-align: left;
        padding-bottom: 8px;
      }

      .field {
        input {
          min-height: 62px;
          padding: 16px 22px;
          border: 1px solid #d8d8d8;
          border-radius: 10px;
          width: 100%;
        }

        .input::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: -0.01em;
          text-align: left;
        }

        .input-error-message {
          border: 1px solid #e8021d;
        }

        .error-message {
          font-size: 12px;
          color: #e8021d;
          margin-top: 0.3rem;
        }
      }
    }

    .wr-main-wrapper {
      .error-message {
        font-size: 12px;
        color: #e8021d;
        margin-top: 0.3rem;
      }
    }
  }
}

.verify-guest {
  background-color: $primary;
  height: 100vh;
  overflow-y: auto;

  .order-histroty {
    height: 100%;

    .order-history-wrapper {
      display: flex;
      padding: 44px 60px 0 60px;
      justify-content: space-between;
      align-items: center;
      margin: auto;

      .orderh-histroy-btn-wrapper {
        display: flex;

        .order-back-btn {
          background-color: #000;
          font-size: 16px;
          font-weight: 600;
          height: 34px;
          padding: 0 15px;
          border-radius: 4px;
          border: 1px solid #000;
        }

        .wr-sign-in-btn {
          .sign-in-btn {
            width: 50px;
          }
        }

        .round-button {
          background-color: #000000;
          color: #fff;
          border: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          padding: 0px;
        }

        .web-buttons {
          display: flex;

          @media only screen and (max-width: 992px) {
            display: none;
          }
        }

        .mobile-buttons {
          display: none;

          @media only screen and (max-width: 992px) {
            display: flex;
          }
        }
      }

      .logo {
        .logo-img {
          display: block;

          @media (max-width: 767.98px) {
            display: none;
          }
        }

        .back-btn {
          display: none;

          @media (max-width: 767.98px) {
            display: block;
          }
        }
      }

      @media (max-width: 767.98px) {
        padding: 44px 20px 0 20px;
      }
    }

    .wr-order-histroy {
      color: black;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      background-color: #ffffff;
      margin: auto;
      padding: 0 82px 50px 82px;
      max-width: 1439.98px;
      min-height: calc(100vh - 136px);

      .order-hsitory-info {
        display: flex;
        align-items: flex-start;
        background-color: #f6f6f6;
        border-radius: 6px;
        padding: 8px;
        margin-bottom: 15px;

        p {
          font-size: 14px;
          margin-bottom: 0px;
          padding-left: 8px;

          a {
            margin-left: 5px;
            text-decoration: none;
            color: $primary;
            font-weight: 600;
          }
        }

        .icon-side {
          svg {
            color: #818181;
            width: 20px;
            height: 20px;
            vertical-align: top;
          }
        }
      }

      .sticky-top {
        background-color: $white;
        padding-bottom: 20px !important;
      }

      .wr-search {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .order-history-title {
          font-size: 36px;
          color: #111;
          font-weight: 600;

          @media (max-width: 767.98px) {
            font-size: 22px;
          }
        }

        .order-history-button {
          display: flex;
          align-items: baseline;

          .dropdown {
            width: 100%;
          }

          .order-histroy-search {
            border: 1px solid #d8d8d8;
            border-radius: 35px;
            min-width: 350px;
            width: 100%;
            height: 41px;
            padding: 1rem;
            box-shadow: none;

            &::placeholder {
              font-size: 14px;
              color: #8e8e8e;
            }

            @media (max-width: 767.98px) {
              margin-right: 0 !important;
              min-width: 100%;
            }
          }

          .order-status-dropdown-button {
            width: 100%;

            .btn-primary {
              background: #fff !important;
              border: 1px solid #d8d8d8 !important;
              border-radius: 10px;
              color: #111;
              font-size: 14px;
              font-weight: 400;
              min-width: 140px !important;
              height: 41px !important;
              box-shadow: none;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &::after {
                content: none;
                height: 10px;
                border: none;
                margin-right: 1em !important;
              }

              @media (max-width: 767.98px) {
                width: 100% !important;
              }

              .dropdown-icon-sdie {
                display: flex;
                padding-right: 0.5rem;
                padding-left: 1rem;

                .dropdown-vr-line {
                  border-right: 1px solid #d8d8d8;
                  height: 10px;
                }
              }
            }

            .dropdown-menu {
              margin-top: 10px;
              transform: translate3d(3px, 43px, 0px) !important;
              border-radius: 10px;
              min-width: 148px !important;

              @media (max-width: 767.98px) {
                width: 100%;
              }
            }

            @media (max-width: 767.98px) {
              width: 100% !important;
              margin-top: 1rem;
            }
          }

          .dropdown-cheveron {
            margin-left: 1rem;
            margin-right: 0;
            width: 15px;
            transition: transform 0.3s ease;
          }

          .dropdown.show .dropdown-cheveron {
            transform: rotate(180deg);
          }

          @media (max-width: 768.98px) {
            flex-direction: column;
            width: 100%;
          }
        }

        @media (max-width: 991.98px) {
          flex-direction: column;
          padding: 0 20px 0 20px;
        }
      }

      .wr-order-history-card {
        margin: auto;
        overflow: hidden;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #ebebeb;
        }

        &::-webkit-scrollbar-thumb {
          background: #c0c0c0;
          border-radius: 6px;
        }

        .main-card-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 12px;

          .m-2 {
            border: none !important;
          }

          // .order-history-card {
          //   background-color: #e5fcf8;
          //   border: 1px solid $primary;
          //   min-height: 260px;
          //   width: 100%;
          //   // min-width: 393px;
          //   // max-width: 396px;
          //   border-radius: 14px;
          //   width: 100%;
          //   padding: 32px 23px 18px 23px;

          //   .history-card-header {
          //     .title-section {
          //       display: flex;
          //       justify-content: space-between;

          //       .title {
          //         display: flex;
          //         flex-direction: column;

          //         .title-label-two {
          //           color: #797979;
          //           font-size: 16px;
          //           font-weight: 600;
          //         }
          //       }

          //       .order-number {
          //         display: block;

          //         @media (max-width: 991.98px) {
          //           display: none;
          //         }
          //       }

          //       .order-number-mobile {
          //         display: none;

          //         @media (max-width: 991.98px) {
          //           display: block;
          //         }
          //       }

          //       .mobile-status {
          //         padding: 8px 20px;
          //         background-color: #ffd783;
          //         border: 1px solid #d68227;
          //         border-radius: 34px;
          //         height: 34px;
          //         max-width: 114px;
          //         display: flex;
          //         align-items: center;
          //         justify-content: center;
          //         color: #d68227;
          //         font-size: 14px;
          //         font-weight: 500;
          //         text-align: center;
          //       }

          //       .bold-text {
          //         color: #111;
          //         font-size: 20px;
          //         font-weight: 700;
          //       }
          //     }
          //   }

          //   .horizonal-dash-line {
          //     border-bottom: 2px dotted #c4c0c0;
          //     width: 100%;
          //     position: absolute;
          //     width: 100%;
          //     left: 50%;
          //     -webkit-transform: translateX(-50%) translateY(-50%);
          //     -moz-transform: translateX(-50%) translateY(-50%);
          //     transform: translateX(-50%) translateY(-50%);
          //   }

          //   .histroy-card-body {
          //     .small-text {
          //       font-size: 16px;
          //       color: #7d7b7b;
          //       font-weight: 400;
          //     }

          //     .bold-text {
          //       color: #333;
          //       font-size: 18px;
          //       font-weight: 600;
          //     }

          //     .order-section {
          //       display: flex;
          //       justify-content: space-between;

          //       .wr-price {
          //         position: relative;
          //         padding-right: 3.5rem;
          //       }
          //     }

          //     .wr-reference {
          //       display: flex;
          //       flex-direction: column;

          //       .order-title-label {
          //         font-size: 14px;
          //         font-weight: 400;
          //         color: #7d7b7b;
          //       }

          //       .order-number {
          //         font-size: 18px;
          //         font-weight: 600;
          //         color: #333333;
          //       }
          //     }
          //   }

          //   .resend-btn-wrapper {
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;

          //     .status-pending {
          //       padding: 8px 20px;
          //       background-color: #ffd783;
          //       border: 1px solid #d68227;
          //       border-radius: 34px;
          //       height: 34px;
          //       max-width: 114px;
          //       display: flex;
          //       align-items: center;
          //       justify-content: center;
          //       color: #d68227;
          //       font-size: 14px;
          //       font-weight: 500;
          //       text-align: center;
          //     }

          //     .status-complete {
          //       @extend .status-pending;
          //       background-color: #92d36e;
          //       border: 1px solid #40672a;
          //       color: #40672a;
          //     }

          //     .resend-btn {
          //       background: $primary !important;
          //       border: 1px solid #059da4;
          //       color: #222;
          //       font-size: 14px;
          //       font-weight: 500;
          //       max-width: 202px;
          //       height: 42px;
          //       box-shadow: none;
          //       cursor: pointer;
          //       border-radius: 10px;

          //       &:hover {
          //         background: #00b69a !important;
          //         border: none !important;
          //       }
          //     }

          //     @media (max-width: 991.98px) {
          //       display: none;
          //     }
          //   }

          //   .resend-btn-wrapper-mobile {
          //     display: none;
          //     .resend-btn {
          //       @media (max-width: 991.98px) {
          //         display: flex;
          //         justify-content: center;
          //         align-items: center;
          //         height: 50px;
          //         background: $primary !important;
          //         border: none;
          //         color: #222;
          //         font-size: 14px;
          //         font-weight: 500;
          //         width: 100%;
          //         position: absolute;
          //         margin-left: -1.5rem;
          //         bottom: 0;
          //         border-radius: 0px 0px 15px 15px;
          //       }
          //     }
          //     @media (max-width: 991.98px) {
          //       display: block;
          //     }
          //   }
          // }

          .order-history-card {
            border-radius: 14px;
            border: 1px solid #00e1be;
            background: #e5fcf8;
            padding: 0px;

            .card-header {
              background-color: transparent;
              padding: 20px 30px;
              border-bottom: 1px dashed #c4c0c0;

              .title-web {
                .title-label {
                  color: #111;
                  font-size: 20px;
                  font-weight: 700;
                  line-height: normal;
                  letter-spacing: -0.2px;
                  margin-bottom: 0px;
                }

                .title-value {
                  color: #111;
                  font-size: 20px;
                  font-weight: 700;
                  line-height: normal;
                  letter-spacing: -0.2px;
                  margin-bottom: 0px;
                }
              }

              .title-mobile {
                display: none;
              }
            }

            .card-body {
              background-color: transparent;
              padding: 20px 30px;

              .data-label {
                margin-bottom: 15px;

                .body-label {
                  color: #7d7b7b;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .body-value {
                  color: #333;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: -0.18px;
                  margin-bottom: 0px;
                }
              }
            }

            .card-footer {
              background-color: transparent;
              padding: 0px 30px 20px 30px;
              border-top: 0px;

              .status-button-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .status-badge {
                  border-radius: 35px;
                  border: 1px solid #40672a;
                  background: #92d36e;
                  padding: 8px 20px;
                  color: #40672a;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: -0.14px;
                }

                .resend-btn {
                  .btn-primary {
                    border-radius: 10px;
                    border: 1px solid #059da4;
                    background: #00e1be;
                    padding: 8px 20px;
                    color: #111;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.14px;
                    display: flex;
                    align-items: center;
                    margin-left: 10px;

                    img {
                      margin-right: 8px;
                    }
                  }
                }
              }

              .status-button-wrapper.pending-status {
                justify-content: center;

                .status-badge {
                  border: 1px solid #d68227;
                  background: #ffd783;
                  color: #d68227;
                }
              }
            }

            @media (max-width: 768px) {
              .card-header {
                .title-web {
                  display: none;
                }

                .title-mobile {
                  display: block;

                  .title-label {
                    color: #7d7b7b;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.14px;
                    margin-bottom: 0px;
                  }

                  .title-value {
                    color: #333;
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.19px;
                    margin-bottom: 0px;
                  }

                  .status-badge {
                    border-radius: 35px;
                    border: 1px solid #40672a;
                    background: #92d36e;
                    padding: 8px 20px;
                    color: #40672a;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.14px;
                    text-align: center;
                    width: fit-content;
                    float: right;
                  }

                  .status-badge.status-pending {
                    border: 1px solid #d68227;
                    background: #ffd783;
                    color: #d68227;
                  }
                }
              }

              .card-footer {
                padding: 0px;

                .status-badge {
                  display: none;
                }

                .resend-btn {
                  width: 100%;

                  .btn-primary {
                    width: 100%;
                    border-radius: 14px !important;
                    border: 0px !important;
                    border-top-left-radius: 0px !important;
                    border-top-right-radius: 0px !important;
                    padding: 18px 20px !important;
                    justify-content: center;
                    margin-left: 0px !important;
                  }
                }
              }
            }
          }

          @media (max-width: 1199.98px) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 767.98px) {
            grid-template-columns: 1fr;
            padding: 0 !important;
          }
        }

        @media (max-width: 767.98px) {
          gap: 12px;
        }

        .wr-message-image {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .wr-image {
            display: flex;
            justify-content: center;

            .message-img {
              width: 100%;
            }
          }
        }

        .wr-title1-empty {
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
          letter-spacing: -0.01em;
          color: #111111;
          margin-bottom: 12px;
        }

        .wr-title2-empty {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: rgba(17, 17, 17, 0.5);
        }

        .wr-text-bold {
          font-size: 24px;
          font-weight: 700;
          color: rgba(17, 17, 17, 0.5);
          text-align: center;
        }

        .wr-text-light {
          font-size: 18px;
          font-weight: 400;
          text-align: center;
        }

        .list-end-message {
          color: #111;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.22px;
          text-align: center;
          margin-top: 50px;
        }

        .spinner-loader-wrapper {
          position: relative;
          z-index: 999;
          text-align: center;
          bottom: -25px;
          padding-bottom: 50px;
          padding-top: 20px;

          @media (max-width: 525.98px) {
            bottom: 0px;
          }
          .spinner-border {
            width: 80px;
            height: 80px;
            border: 0.5em solid $primary;
            border-right-color: transparent;
            overflow: hidden;
          }
        }
      }

      @media (max-width: 1198.98px) {
        padding: 0 22px 0 22px;
      }
    }
  }
}

.buycertificate-screens {
  overflow: hidden;

  .background-img {
    position: relative;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url('../assets/images/Buy-certificate-bg.png');
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    z-index: 0;
    overflow: hidden;

    .logo-wrapper {
      padding-left: 3rem;
      padding-top: 3rem;

      @media (max-width: 768px) {
        padding: 10px 0 0 32px;
        margin-top: 1rem;
      }

      .logo-top {
        width: 100px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .form-name-green {
      display: none;

      @media (max-width: 768px) {
        display: block;
        color: #ffffff;
        font-size: 38px;
        font-weight: 500;

        .form-name-green-mobile {
          color: $primary;
          font-weight: 600;
        }
      }
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 999;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      transition: 0.5s;
      overflow: hidden;

      .close-btn {
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 45px;
        font-size: 60px;
        cursor: pointer;
      }

      .logo-wrapper {
        padding-left: 3.25rem;
        margin-top: 0;
        background-color: #111;
        padding-top: 2rem;
      }
    }
  }

  .faq-btn {
    position: absolute;
    left: 10px;
    top: 45%;
    height: 171px;
    width: 38px;
    background-color: $primary;
    border: none !important;
    color: #222;
    font-size: 16px;
    font-weight: 600;
    border-radius: 0 10px 10px 0;
    text-orientation: upright;
    white-space: nowrap;
    padding: 15px 10px;

    &:hover {
      background-color: #00b69a;
      box-shadow: none;
    }

    &:focus {
      background: none;
      box-shadow: none;
    }
  }

  .form-wrapper {
    .buy-certificate-form-wrapper {
      display: flex;
      justify-content: center;
      height: calc(100vh - 84px);
      overflow-y: auto;

      @media (max-width: 768px) {
        height: 100%;
      }

      @media (max-width: 992px) {
        height: 100%;
      }

      .buy-certificate-form {
        display: block;
        padding-left: 30px;
        padding-right: 30px;
        width: 600px;

        @media (max-width: 992px) {
          padding-left: 30px;
          padding-right: 30px;
          width: 100%;
        }

        .form-name {
          color: #111;
          font-size: 40px;
          font-weight: 600;

          .form-name-green {
            color: $primary;
            font-weight: 600;
          }
        }

        .form-description {
          font-size: 14px;
          margin-bottom: 0px;
          color: #707070;
        }

        .wr-form-signin-btn {
          display: flex;
          justify-content: flex-end;
          min-width: 150px;
          text-align: center;

          .form-signin-btn {
            min-width: 150px;
            border-radius: 37px;
            height: 49px;
            font-size: 16px;
            font-weight: 600;

            &:hover {
              box-shadow: none;
              color: #000000;
              letter-spacing: 0.25px;
              background-color: rgba(53, 53, 53, 0.15);
              font-weight: 700;
            }
          }

          @media (max-width: 767.98px) {
            display: none;
          }
        }

        .form-label {
          margin-bottom: 16px;
          margin-top: 10px;

          label {
            color: #111;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 0px;
          }

          p {
            font-size: 14px;
            margin-bottom: 0px;
            color: #707070;
            max-width: 625px;

            svg {
              margin-right: 5px;
              font-size: 15px;
              color: #707070;
            }
          }
        }

        .certificate-form-input {
          border: 1px solid $primary;
          border-radius: 10px;
          height: 62px;
          background: #fff;
          box-shadow: none;
        }

        .price-input-label {
          span {
            border: 1px solid $primary;
            border-radius: 10px;
            height: 62px;
            background: #dedede;
            box-shadow: none;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            color: #a3a3a3;
            font-weight: 600;
          }
        }

        .input-error-message {
          border: 1px solid #dc3545;
        }

        .disabled-input {
          background-color: #f8f8f8;
        }

        .gift-area-wrapper {
          .gift-area-button-wrapper {
            display: flex;

            .gift-area-button {
              width: 100%;
              border-radius: 36px;
              min-height: 49px;
              border: 1px solid #111;
              font-size: 16px;
              font-weight: 700;
              box-shadow: none;

              &:hover {
                color: #fff;
                background-color: #111;
              }
            }

            .active {
              color: #fff;
              background-color: #111;
            }
          }
        }

        .form-text {
          color: #111;
          font-size: 15px;
          font-weight: 500;

          .form-span {
            color: $primary;
            text-decoration: none !important;
          }
        }

        .form-check-input {
          background-color: #ffffff;
          border: 1px solid #111;
        }

        .form-check-input:checked[type='radio'] {
          background-image: url(../assets/images/checkmark.svg);
        }

        .form-check-input:checked[type='checkbox'] {
          background-color: #222;
          box-shadow: none !important;
        }

        .link-span {
          color: #222;
          text-decoration: underline;
        }

        .center-button-container {
          display: flex;
          justify-content: center;
        }

        .submit-button {
          width: 100%;
          background-color: $primary;
          border-radius: 36px;
          color: #222;
          height: 49px;
          font-size: 16px;
          font-weight: 600;
          border: none !important;

          &:hover {
            background-color: #00b69a;
          }
        }

        .purchase-text-wrapper {
          .purchase-text-title {
            font-size: 30px;
            font-weight: 700;
            color: #111;
            line-height: 36px;

            @media (max-width: 1200px) {
              font-size: 30px;
              line-height: 34px;
            }

            @media (max-width: 992px) {
              font-size: 24px;
              line-height: 28px;
            }
          }

          .purchase-text {
            font-size: 20px;
            color: #7d7b7b;
            font-weight: 500;
          }

          .purchase-text-small {
            font-size: 16px;
            color: #7d7b7b;
            font-weight: 400;
          }

          .dash-lines {
            border: none;
            border-top: 2px dotted #7d7b7b;
            color: #fff;
            background-color: #fff;
            height: 1px;
          }

          .wr-purchase-summery {
            padding: 22px 25px;
            background-color: #e5fcf8;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .wr-purchase-summery-title {
              display: flex;

              .purchase-summery-title {
                color: $primary;
                font-size: 20px;
                font-weight: 500;
                padding-left: 0.5rem;
              }
            }

            .wr-main-details-list {
              justify-content: space-around;

              .table {
                th,
                td {
                  border-bottom: none;
                }

                tr {
                  @media (max-width: 992px) {
                    border-bottom: 1px solid $primary;

                    &:last-child {
                      border-bottom: 0px;
                    }
                  }
                }

                .black-text {
                  color: #000;
                  font-size: 16px;
                  font-weight: 500;

                  @media (max-width: 992px) {
                    font-size: 14px;
                  }
                }

                .grey-text {
                  color: #8e8e8e;
                  font-size: 16px;
                  font-weight: 500;

                  @media (max-width: 992px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .wr-purchase-summery-button {
            .purchase-summery-button {
              width: 100% !important;
              border-radius: 30px;
              height: 47px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .wr-purchase-summery-link {
            justify-content: space-between;
            display: flex;

            @media (max-width: 992px) {
              flex-direction: column;
            }

            .purchase-summery-link-text {
              color: #000;
              font-size: 20px;
              margin-bottom: 0px;
            }

            .links-container {
              display: flex;
              flex-direction: column;
            }

            .purchase-summery-link {
              color: $primary;
              font-size: 20px;
              margin-bottom: 10px;
            }
          }

          .footer-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .copyright-text {
              margin: 0;
            }

            .privacy-policy {
              padding: 0;
              color: #252729;
              font-size: 14px;
              font-weight: 300;
            }
          }
        }

        // @media (max-width: 767.98px) {
        //   margin-top: 5rem;
        // }

        //Custom radio  buttons
        .form-check {
          padding-left: 0px;
          width: 50%;

          .rad-input[type='radio'] {
            display: none;
          }

          .check-label {
            position: relative;
            width: 100%;
            border-radius: 36px;
            // height: 49px;
            border: 1px solid #111;
            font-size: 16px;
            font-weight: 700;
            box-shadow: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px;
            text-align: center;
            height: 100%;

            &:hover {
              color: #fff;
              background-color: #111;
            }
          }

          .rad-input[type='radio']:checked + .check-label {
            color: #fff;
            background-color: #111;
          }

          &:first-child {
            margin-right: 1rem;
          }
        }

        .email-checkbox {
          width: unset;
        }
      }

      .payment-page-info {
        margin-bottom: 0px;
        margin-top: 20px;
        font-size: 14px;
        color: #515151;

        svg {
          margin-right: 8px;
        }
      }
    }

    .copyright-text {
      font-size: 14px;
      font-weight: 300;
      padding-bottom: 20px;
    }
  }

  .modal-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 616px !important;
    border-radius: 5px;
    margin: auto !important;

    @media (max-width: 768px) {
      width: 100% !important;
      margin: auto;
    }

    .modal-header {
      border-bottom: 0px;
    }

    .modal-content {
      border-radius: 10px !important;

      @media (max-width: 768px) {
        // height: calc(100vh - 70px);
        height: fit-content;
        width: calc(100vw - 70px);
        min-width: 394px;
      }

      @media (max-width: 425px) {
        min-width: 320px;
      }

      @media (max-width: 375.98px) {
        height: fit-content;
        min-width: 300px;
      }

      .sign-in-modal {
        display: flex;
        justify-content: center;
        padding: 22px 70px;

        @media (max-width: 600px) {
          padding: 21px;
        }

        .sign-in-modal-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .modal-text-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .modal-main-title {
              font-size: 30px;
              font-weight: 600;
              color: #111;

              @media (max-width: 992px) {
                font-size: 22px;
              }
            }

            .arrow-forward {
              width: 24px;
              padding-bottom: 0.4rem;
            }
          }

          .modal-text-box-wrapper {
            padding: 18px 23px;
            background: #eefffc;
            border: 1px solid #00e1be;
            border-radius: 10px;

            .modal-text-box-para {
              font-size: 15px;
              font-weight: 300;
              color: #111;

              .green-text {
                color: $primary;
                font-weight: 600;
              }
            }

            .verify-text-area {
              font-size: 16px;
              font-weight: 600;
              color: #252729;
            }

            .modal-link-text {
              text-decoration: underline;
              color: #252729;
            }

            .check-box-text-wrapper {
              display: flex;

              .check-box {
                .form-check-input {
                  box-shadow: none;

                  &:checked {
                    background-color: #000;
                    color: #fff;
                    border: none;
                  }
                }
              }
            }
          }

          .submit-button {
            width: 100%;
            background-color: $primary;
            border: none;
            border-radius: 36px;
            height: 49px;
            color: #000;
            font-size: 16px;
            font-weight: 600;
          }

          .form-check {
            .form-check-input {
              box-shadow: none;

              &:checked {
                background-color: #000;
                color: #fff;
              }
            }

            .terms-text {
              font-size: 14px;
              color: #252729;
              font-weight: 600;

              .terms-link {
                @extend .terms-text;
              }
            }
          }

          .text-danger {
            font-size: 14px;
          }

          .payment-options-section {
            width: 100%;

            h3 {
              font-size: 16px;
              font-weight: 600;
            }

            .payment-options {
              width: 100%;
              display: flex;

              .form-check {
                width: 50%;
                padding-left: 0px;

                input {
                  display: none;
                }

                label {
                  position: relative;
                  width: 100%;
                  border: 1px solid #000;
                  text-align: center;
                  padding: 8px 10px;
                  border-radius: 8px;
                  font-size: 14px;
                  font-weight: 500;
                  cursor: pointer;

                  svg {
                    font-size: 22px;
                  }

                  .card-logo {
                    display: flex;
                    justify-content: center;

                    img {
                      width: 25px;
                      height: auto;
                      margin-right: 5px;

                      &:last-child {
                        margin-right: 0px;
                      }
                    }
                  }

                  &:hover {
                    background-color: #000;
                    color: #fff;
                    cursor: pointer;

                    svg {
                      color: $primary;
                    }
                  }
                }

                input[type='radio']:checked + label {
                  background-color: #000;
                  color: #fff;

                  svg {
                    color: $primary;
                  }
                }

                &:first-child {
                  padding-right: 10px;
                }

                &:last-child {
                  padding-left: 10px;
                }
              }

              @media (max-width: 767.98px) {
                flex-direction: column;

                .form-check {
                  width: 100%;

                  &:first-child {
                    padding-right: 0px;
                  }

                  &:last-child {
                    padding-left: 0px;
                    margin-top: 10px;
                  }
                }
              }
            }
          }

          .sign-up-text {
            font-size: 16px;
            color: #111;
            font-weight: 600;
          }

          .sign-up {
            color: $primary;
          }

          .wr-vertical-lines {
            display: flex;
            align-items: center;

            .vertical-lines {
              border-bottom: 1px solid rgba(0, 0, 0, 0.5);
              width: 100px;
            }
          }

          .btn {
            max-width: 432px;
          }

          .modal-btn {
            width: 100%;
            height: 49px;
            border-radius: 36px;
            color: #111;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            background-color: #00e1be;
            border: none;
          }

          .btn-black {
            background-color: #000 !important;
            color: #ffffff !important;
          }

          .guest-btn {
            font-size: 16px;
            color: #111111;
            background-color: $primary;
            border-radius: 36px;
            width: 100%;
            height: 49px;
            font-weight: 600;
            text-decoration: none;
          }

          .modal-footer {
            display: flex;
            flex-direction: column;
            border-top: none;

            .footer-text {
              font-size: 12px;
              color: #111;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .faq-modal-wrapper {
    background-color: #111;
    color: #ffffff;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      background-color: #111;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 62, 52, 0.5);
      border: 1px solid $primary;
      border-radius: 10px;
    }

    .faq-modal-title {
      font-size: 30px;
      font-weight: 500;
      padding-left: 2rem;
    }

    .modal-header {
      border-bottom: none;

      .btn-close {
        background-image: url('../assets/images/modal-close-btn.svg');
        margin: -5.5rem -0.5rem -0.5rem auto;
      }
    }

    .faq-modal-body {
      .wr-faq-modal-body {
        .faq-accordion-item {
          background-color: #111;

          .faq-accordion-header {
            .accordion-button {
              background-color: rgba(0, 62, 52, 0.5);
              border-radius: 10px;
              border: 1px solid $primary;
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              box-shadow: none;

              &::after {
                background-image: url('../assets/images/uil_plus.svg');
                transform: unset;
              }

              &:not(.collapsed)::after {
                background-image: url('../assets/images/Subtract.svg') !important;
              }
            }
          }

          .accordion-collapse {
            .accordion-body {
              .font-italic {
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
}

//payment gateway styling
.payment-page {
  .payment-background {
    position: relative;
    background-image: url('../assets/images/payment-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #000;
    height: 100vh;
    padding: 30px 30px;
    overflow-y: auto;

    .cart-product-data {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .payment-header {
        .back-btn {
          background: none;
          border: none;
          padding-left: 0;
        }

        .white-logo {
          min-width: 100px;
          max-width: 100px;
        }
      }

      .payment-detail-wrapper {
        margin-top: 30px;

        .payment-details-text-wrapper {
          margin-top: 20px;
          margin-bottom: 20px;
          padding-left: 33px;

          .payment-details-title {
            color: #ffffff;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0px;
          }

          .payment-details-price {
            color: #fff;
            font-size: 50px;
            font-weight: 500;
            margin-bottom: 0px;
          }

          .payment-details-text {
            color: #c4c0c0;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 0px;
          }
        }

        @media (max-width: 991.98px) {
          display: none;
        }
      }

      .mobile-payment-detail-wrapper {
        display: none;

        @media (max-width: 991.98px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 60px;

          .image-wrapper {
            .mobile-screen-image {
              max-width: 250px;
              width: 100%;
            }
          }

          .main-title {
            font-size: 20px;
            font-weight: 500;
            color: #fff;
          }

          .sub-title {
            font-size: 16px;
            color: $primary;
            font-weight: 500;
          }

          .price-tag {
            font-size: 50px;
            font-weight: 500;
            color: #fff;

            span {
              margin-left: 10px;
            }
          }

          .text-bottom {
            font-size: 16px;
            color: #c4c0c0;
            font-weight: 400;
          }
        }

        .total-detail-wrapper {
          margin-bottom: 20px;
          padding-left: 0px;
          padding-right: 0px;
          min-width: 400px;

          @media (max-width: 500.98px) {
            min-width: 300px;
          }

          .total-detail {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .total-title {
              .main-title {
                font-size: 20px;
              }
            }

            .price-tag-wrapper {
              .price-tag {
                font-size: 20px;
              }
            }
          }

          .total-detail.other-details {
            .total-title {
              .main-title {
                font-size: 14px;
              }
            }

            .price-tag-wrapper {
              .price-tag {
                font-size: 14px;
              }
            }
          }
        }
      }

      .payment-detail-box-wrapper {
        display: flex;
        max-width: 100%;
        justify-content: space-between;
        align-items: normal;
        background: rgba(0, 62, 52, 0.5);
        padding: 33px 30px;
        border: 1px solid $primary;
        border-radius: 10px;
        height: 132px;
        margin-bottom: 20px;

        .payment-detail-box {
          display: flex;
          align-items: center;

          .image-wrapper {
            width: 80px;
            height: 80px;
            background-color: $primary;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            .mobile-img {
              height: 61px;
            }
          }

          .text-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .main-title {
              font-size: 20px;
              font-weight: 500;
              color: #fff;
            }

            .sub-title {
              font-size: 20px;
              font-weight: 500;
              color: $primary;
            }
          }
        }

        .price-tag-wrapper {
          font-size: 20px;
          font-weight: 500;
          color: #fff;
          display: flex;

          .price-tag {
            display: flex;
            gap: 10px;

            span {
              min-width: 80px;
              display: block;
              text-align: right;
            }
          }
        }

        @media (max-width: 767.98px) {
          display: none;
        }
      }

      .total-detail-wrapper {
        max-width: 100%;
        align-items: normal;
        padding-left: 33px;
        padding-right: 33px;

        .total-detail {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;

          .total-title {
            .main-title {
              color: #fff;
              font-size: 22px;
              font-weight: 500;
              margin-bottom: 0px;
            }
          }

          .price-tag-wrapper {
            .price-tag {
              color: #fff;
              font-size: 22px;
              font-weight: 500;
              margin-bottom: 0px;
              display: flex;
              gap: 10px;

              span {
                min-width: 80px;
                display: block;
                text-align: right;
                word-break: break-all;
              }

              @media (max-width: 767.98px) {
                margin-left: 15px;

                // span {
                //   minwidth: 65px;
                // }
              }
            }
          }
        }

        .total-detail.other-details {
          margin-top: 0px;

          .total-title {
            .main-title {
              font-weight: 400;
              font-size: 18px;
            }
          }

          .price-tag-wrapper {
            .price-tag {
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
      }
    }

    .payment-footer {
      display: flex;
      align-items: center;
      font-family: 'Jost', sans-serif;

      .logo-text {
        margin: 0;
        color: #a3a7ae;
        font-size: 20px;
      }

      .stripe-logo {
        width: 48px;
      }

      .vl {
        border-left: 1px solid #fff;
        height: 20px;
        margin-left: 1rem;
      }

      .payment-footer-links {
        font-size: 20px;
        font-weight: 400;
        color: #a3a7ae;
      }

      @media (max-width: 767.98px) {
        display: none;
      }
    }

    @media (min-width: 1399.98px) {
      padding: 50px 50px;
      min-height: auto;
    }

    @media (max-width: 992px) {
      height: auto;
      overflow: unset;
    }
  }

  .stripe-form-wrapper {
    display: flex;
    justify-content: center;
    height: calc(100vh - 98px);
    overflow-y: auto;

    @media (max-width: 768px) {
      height: 100%;
    }

    @media (max-width: 992px) {
      height: 100%;
    }

    .stripe-form-data {
      display: block;
      padding-left: 30px;
      padding-right: 30px;
      width: 600px;

      @media (max-width: 992px) {
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
      }

      .stripe-form-title {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        color: #000000;

        @media (max-width: 768.98px) {
          font-size: 30px;
        }

        @media (max-width: 375.98px) {
          font-size: 28px;
        }
      }

      .stripe-form-input {
        .email-label-wrapper {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;

          .stripe-form-input-label {
            font-size: 16px;
            font-weight: 400;
            color: #111;
          }

          .email-label-text {
            margin-bottom: 0.5rem !important;
            font-size: 12px;
            font-weight: 300;
            line-height: 15px;
            letter-spacing: -0.01em;

            @media (max-width: 768.98px) {
              display: block;
              width: 100%;
              text-align: right;
            }
          }

          .warning-icon {
            width: 19px;
            height: 16px;
            margin-right: 4px;
            padding-bottom: 1px;
          }

          @media (max-width: 991.98px) {
            justify-content: flex-end;
          }
        }

        .stripe-form-input {
          border: 1px solid #00e1be;
          border-radius: 10px;
          background: #fff;
          height: 49px;

          &::placeholder {
            color: #5f646c;
          }
        }

        .stripe-form-input.grey-input {
          background: #f8f8f8;
        }

        .invalid-input-values {
          border: 1px solid #dc3545;
        }
      }

      .stripe-card {
        z-index: 1;
        position: relative;

        .stripe-card-text {
          padding-left: 0.5rem;
          position: relative;

          .card-element {
            position: relative;
            z-index: 1;
          }
        }
      }

      .card-details-wrapper {
        z-index: 999;
        position: relative;

        .card-number-wrapper {
          border: 1px solid #00e1be;
          border-radius: 10px 10px 0 0;
          height: 49px;

          .card-icons {
            padding-right: 0.5rem;
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 0.5rem;

            .stripe-cards-logos {
              width: 100% !important;

              @media (max-width: 991.98px) {
                max-width: 100px !important;
              }
            }
          }
        }

        .card-sub-details-wrapper {
          display: flex;

          .card-number-half {
            border: 1px solid #00e1be;
            border-top: none;
            width: 100%;
            border-radius: 0 0 0 10px;
          }

          .cvc-input {
            @extend .card-number-half;
            border-radius: 0 0 10px 0;
            border-left: none;
          }
        }
      }

      .payment-message {
        font-size: 12px;
        color: #e8021d;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
      }

      .stripe-form-submit {
        width: 100%;
        background-color: $primary;
        border-radius: 36px;
        height: 49px;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: #111;

        .spinner-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .payment-page-info {
      margin-bottom: 0px;
      margin-top: 20px;
      font-size: 14px;
      color: #515151;

      svg {
        margin-right: 8px;
      }
    }
  }

  .payment-reference {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
      margin-right: 8px;
    }

    p {
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 400;
      color: #626262;
    }

    @media (max-width: 992px) {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;

      h3,
      p {
        text-align: left;
      }
    }
  }
}

.bank-details-card {
  border: 0px;
  padding: 0px;

  .card-body {
    padding: 0px;

    .card-title {
      font-size: 20px;
      font-weight: 600;
    }

    .card-text {
      .label {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 500;
        color: #626262;
      }

      .data {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 400;
        color: #626262;
        margin-left: 8px;
      }

      svg {
        margin-left: 10px;
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }

      .btn-link {
        padding: 0px;
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

//Page not found
.page-not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .content-wrapper {
    text-align: center;

    .sad-icon {
      font-size: 70px;
      color: $primary;
    }

    h1 {
      font-weight: 700;
      font-size: 50px;
      margin-bottom: 25px;
    }

    a {
      font-size: 20px;
      font-weight: 400;
      text-decoration: none;
      color: #008acf;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.radio-uk {
  @media (min-width: 768px) and (max-width: 991.98px) {
    .check-label {
      min-height: 75px;
    }
  }

  @media (max-width: 425.98px) {
    .check-label {
      min-height: 118px;
    }
  }
}

.mdl-bank-acc-detail {
  transform: translate(0, 100px);
  .modal-header {
    font-weight: 700;
  }
}